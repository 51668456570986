import React from 'react';
import { Layout, Link } from 'components/ui';
import { Center, Heading, Spacer, Text, VStack } from '@chakra-ui/react';

function notFound() {
  return (
    <Layout>
      <Center h="100%">
        <VStack>
          <Heading>404: Page not found</Heading>
          <Spacer />
          <Spacer />
          <Text>
            The requested resource could not be found but may be available again
            in the future.
          </Text>
          <Spacer />
          <Link href={'/'} colorScheme="orange">
            Go back home
          </Link>
        </VStack>
      </Center>
    </Layout>
  );
}

export default notFound;
